import React from "react";

export default function Button({ children, onClick, className }) {
  return (
    <button
      className={`rounded-full border-[#1D1D1F1F] hover:border-gray-400 border-[1px] flex justify-center items-center  transition duration-200 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

