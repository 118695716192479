import { configureStore } from "@reduxjs/toolkit";
import customizerReducer from "./customizerSlice";
import productReducer from "./productSlice";
import assetsReducer from "./assetsSlice";
import authReducer from "./authSlice";

export const store = configureStore({
  reducer: {
    customizer: customizerReducer,
    products: productReducer,
    assets: assetsReducer,
    auth: authReducer,
  },
});
