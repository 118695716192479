// styles.js
import React from "react";

export const OptionsWrapper = (props) => (
  <div className={`w-full space-y-2 ${props.className}`}>{props.children}</div>
);

export const CategoryWrapper = (props) => (
  <div className="flex flex-col mt-20 space-y-4 px-5">{props.children}</div>
);

export const SelectBtnWrapper = (props) => (
  <div className={`border border-gray-300 rounded-md h-20 w-auto hover:border-tertiary-light ${props.className}`}>
    {props.children}
  </div>
);

export const HelperContainer = (props) => (
  <div className={`bg-secondary-light rounded-md w-auto p-4 ${props.className}`}>{props.children}</div>
)

export const HelperWrapper = (props) => (
  <div className={`inline-flex justify-between ${props.className}`}>{props.children}</div>
);

