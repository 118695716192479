import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

export const verifyAuth = createAsyncThunk(
  "auth/verifyAuth",
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState();
    const token = auth.accessToken;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get("/api/v1/auth/status", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // { user: {...}, isAuthenticated: true }
    } catch (error) {
      return rejectWithValue("Token verification failed");
    }
  }
);

const initialState = {
  isAuthenticated: false,
  user: null,
  accessToken: localStorage.getItem("accessToken") || null,
  status: "idle", // idle | loading | succeeded | failed
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { accessToken, user } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
      state.accessToken = accessToken;
      localStorage.setItem("accessToken", accessToken);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      localStorage.removeItem("accessToken");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyAuth.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = action.payload.isAuthenticated;
        state.user = action.payload.user;
        // We already had the accessToken from initialState if existed
      })
      .addCase(verifyAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to verify token";
        // If token verification fails, consider logging out
        state.isAuthenticated = false;
        state.user = null;
        state.accessToken = null;
        localStorage.removeItem("accessToken");
      });
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
