import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

// Fetch all products
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/v1/products`);
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch a single product by ID
export const fetchProductById = createAsyncThunk(
  "products/fetchProductById",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${SERVER_URL}/api/v1/products/${productId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch product");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a new product
export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await fetch(`${SERVER_URL}/api/v1/products`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      console.log(response);

      if (!response.ok) {
        throw new Error("Failed to create product");
      }

      return await response.json(); // Return the newly created product data
    } catch (error) {
      console.log(error);

      toast.error(error?.message);
      return rejectWithValue(error.message); // Handle errors gracefully
    }
  }
);
// Update product
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, updatedProduct }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/v1/products/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProduct),
      });

      if (!response.ok) {
        throw new Error("Failed to update product");
      }

      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    productDetails: {},
    fetchStatus: "idle", // Status for fetching all products
    fetchProductStatus: "idle", // Status for fetching a single product
    createStatus: "idle", // Status for creating a product
    updateStatus: "idle", // Status for updating a product
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(fetchProducts.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.error = action.payload;
      })

      // Fetch a single product by ID
      .addCase(fetchProductById.pending, (state) => {
        state.fetchProductStatus = "loading";
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.fetchProductStatus = "succeeded";
        state.productDetails[action.payload._id] = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.fetchProductStatus = "failed";
        state.error = action.payload;
      })

      // Create a new product
      .addCase(createProduct.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.items.push(action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.payload;
      })

      // Update product
      .addCase(updateProduct.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        // Update the product in the state with the updated data
        state.productDetails[action.payload._id] = action.payload;
        // Optionally update the product in the items array
        const index = state.items.findIndex(
          (product) => product._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.payload;
      });
  },
});

export default productsSlice.reducer;
