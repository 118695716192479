import React from "react";
import PaginationButtonLeft from "../../../Partials/PaginationButtonLeft";
import PaginationButtonRight from "../../../Partials/PaginationButtonRight";

export default function Pagination() {
  return (
    <div className="flex items-center justify-center w-full my-5">
      <div className="flex space-x-10 items-center">
        <PaginationButtonLeft ariaLabel={"Previous Page"} />
        <div className="flex space-x-2 font-medium text-xs items-center">
        <input
            type="number"
            defaultValue={1}
            placeholder="0"
            className="border border-gray-300 py-2 rounded-md text-center"
            style={{ width: '3ch' }} // Adjusted width for better input space
          />
          <span className="text-secondary-dark">of</span>
          <span className="text-secondary-dark">3</span>
        </div>
        <PaginationButtonRight ariaLabel={"Next Page"} />
      </div>
    </div>
  );
}
