import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronUp } from "lucide-react";
import StyledCheckbox from "./StyledChecked";

const categories = [
  {
    name: "Essentials",
    items: [
      "Any",
      "Casual Wear",
      "Corporate Gifts",
      "Fans",
      "Festivals",
      "Gym",
      "Hospitality",
      "Leisurewear",
      "Lifestyle",
      "Retail",
      "Sport Events",
      "Volunteer",
      "Work Uniform",
      "Yoga",
    ],
  },
  {
    name: "Clubs",
    items: [
      "Any",
      "American Football",
      "Baseball",
      "Basketball",
      "Cricket",
      "Cycling",
      "Fan Gear",
      "Football",
      "Footsal",
      "Gymnastics",
      "Handball",
      "Ice Hockey",
      "Rugby",
      "Running",
      "Tennis",
      "Volleyball",
    ],
  },
  {
    name: "Sustainability",
    items: [
      "Any",
      "Bio-Based Materials",
      "Carbon Neutral",
      "Circular Design",
      "CO2 Captured",
      "FSC Certified",
      "Ocean Bound Plastic",
      "Organic",
      "Recycled Material",
      "Renewable Energy Sources",
    ],
  },
  {
    name: "Collections",
    items: ["Deals", "Best Sellers", "New Arrivals"],
  },
];

export function Sidebar({ isOpen, onClose }) {
  const [expandedCategories, setExpandedCategories] = useState([]);

  const toggleCategory = (categoryName) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryName)
        ? prev.filter((name) => name !== categoryName)
        : [...prev, categoryName]
    );
  };

  return (
    <div className="h-full py-6 px-4 bg-white ">
      {categories.map((category) => (
        <div key={category.name} className="mb-4 border-b border-gray-300/50">
          <motion.button
            whileHover={{ backgroundColor: "rgba(0,0,0,0.05)" }}
            onClick={() => toggleCategory(category.name)}
            className="flex items-center justify-between w-full py-2 px-3 text-left text-gray-900 rounded-lg mb-1"
          >
            <span className="font-medium">{category.name}</span>
            {expandedCategories.includes(category.name) ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </motion.button>
          <AnimatePresence>
            {expandedCategories.includes(category.name) && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="overflow-hidden"
              >
                <div className="ml-4 mt-2 space-y-1">
                  {category.items.map((item) => (
                    <motion.div
                      key={item}
                      whileHover={{ x: 1 }}
                      className="flex items-center space-x-2 px-3 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-lg"
                    >
                      <StyledCheckbox />
                      <span>{item}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
      <div className="flex flex-col px-2 justify-center gap-2">
        <p className="font-bold text-black text-left">
          Your Product Is Not Here?
        </p>
        <p className="text-sm text-gray-500 font-medium">
          Upload your images, and we’ll create the exact product you need, ready
          for you to personalize.
        </p>
        <motion.button
          whileHover={{
            scale: 1.1,
            transform: "translateY(2px)",
            transition: { duration: 0.2, type: "spring", delay: 0.1 },
          }}
          className="bg-black font-semibold text-white rounded-3xl mt-2 shadow-md px-6 py-2"
        >
          Upload Models
        </motion.button>
      </div>
    </div>
  );
}
