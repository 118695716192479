import React from "react";
import { SelectBtnWrapper } from "./wrapper";

export default function SelectBtn({ name, href }) {
  return (
    <SelectBtnWrapper>
      <a href={href} className="w-full h-full flex items-center px-5">
        <span className="font-semibold text-base text-primary">{name}</span>
      </a>
    </SelectBtnWrapper>
  );
}
