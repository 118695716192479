import React, { useState } from "react";
import { motion } from "framer-motion";
import CustomSelect from "./CustomSelect";

export function CategoryFilter({
  category,
  sortBy,
  onCategoryChange,
  onSortChange,
}) {
  const categoryOptions = [
    { value: "tops", label: "Tops & T-shirts" },
    { value: "bottoms", label: "Bottoms" },
    { value: "accessories", label: "Accessories" },
  ];

  const sortOptions = [
    { value: "featured", label: "Featured" },
    { value: "newest", label: "Newest" },
    { value: "Price: Low to High", label: "Price: Low to High" },
    { value: "Price: High to Low", label: "Price: High to Low" },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex items-center gap-4"
    >
      <div className="flex items-center">
        <span className="text-sm text-gray-500 mr-2">Category:</span>
        <CustomSelect
          value={category}
          onChange={onCategoryChange}
          options={categoryOptions}
        />
      </div>
      <div className="flex items-center">
        <span className="text-sm text-gray-500 mr-2 text-nowrap">Sort by:</span>
        <CustomSelect
          value={sortBy}
          onChange={onSortChange}
          options={sortOptions}
        />
      </div>
    </motion.div>
  );
}
