import React from "react";

export default function SelectHeader({title, desc}) {
  return (
    <h3 className="font-semibold text-2xl">
      <span className="text-primary">{title}. </span>
      <span className="text-secondary-dark">{desc}.</span>
    </h3>
  );
}
