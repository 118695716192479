import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inspirationDesign : null,
  modelData: null,
  selectedPart: null,
  selectedOption: null,
  selectedLogoImage: null,
  selectedLogoPrint: null,
  generatedLogoTextures: {},
  isDragging: false,
  userDesign: null,
  parts: [],
  images: [],
};

const customizerSlice = createSlice({
  name: "customizer",
  initialState,
  reducers: {
    addParts: (state, action) => {
      state.parts = action.payload;
    },
    setPart: (state, action) => {
      state.selectedPart = action.payload;
    },
    setOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setLogoImage: (state, action) => {
      state.selectedLogoImage = action.payload;
    },
    setLogoPrint: (state, action) => {
      state.selectedLogoPrint = action.payload;
    },
    setLogotexture: (state, action) => {
      state.generatedLogoTextures = action.payload;
    },
    setModelData: (state, action) => {
      state.modelData = action.payload;
    },
    setUserDesign: (state, action) => {
      state.userDesign = action.payload;
    },
    setInspirationDesign: (state, action) => {
      state.inspirationDesign = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    setIsSaveCustomizer: (state, action) => {
      state.isSaveCustomizer = action.payload;
    },
    setFilterProductTags: (state, action) => {
      state.filterProductTags = action.payload;
    },
    setDragging: (state, action) => {
      state.isDragging = action.payload;
    }
  },
});
 
export const {
  setPart,
  setOption,
  setLogoImage,
  setLogoPrint,
  setLogotexture,
  addParts,
  setInspirationDesign,
  setUserDesign,
  setModelData,
  setImages,
  setIsSaveCustomizer,
  setFilterProductTags,
  setDragging,
} = customizerSlice.actions;
export default customizerSlice.reducer;
