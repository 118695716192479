import React, { useState } from "react";

const StyledCheckbox = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="inline-flex items-center cursor-pointer group">
      <input
        type="checkbox"
        className="hidden peer"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <div
        className={`
        w-5 h-5 rounded-md p-1 border-2
        ${isChecked ? "border-blue-500 bg-blue-500" : "border-gray-300"}
        flex items-center justify-center relative
      `}
      >
        <svg
          className={`
            absolute w-4 h-4 text-white 
            transition-opacity duration-200 ease-in-out
            ${isChecked ? "opacity-100" : "opacity-0"}
          `}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    </label>
  );
};

export default StyledCheckbox;
