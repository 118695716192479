import React from "react";

export default function PaginationButtonLeft({onClick, ariaLabel}) {
  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className="hover:svg-hover"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.75 19.5L8.25 12L15.75 4.5"
          stroke="#848484"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
