import Button from "./Button";
import Icons from "../../assets/icons";

export default function ImageSliderControls({ onPrev, onNext }) {
    return (
      <>
        <Button
          className="absolute left-2 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-secondary-light opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          onClick={onPrev}
        >
          <Icons src="arrowLeft" />
        </Button>
        <Button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-secondary-light opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          onClick={onNext}
        >
          <Icons src="arrowRight" />
        </Button>
      </>
    );
  }