import React, { useState } from "react";
import { motion } from "framer-motion";
import { Share2, ChevronRight, ChevronLeft } from "lucide-react";

const ImageSlider = ({image}) => {
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === image.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? image.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full  mx-auto mt-12">
      <div className="relative bg-gray-50 rounded-lg ">
        <div className="absolute top-4 left-4 flex gap-2 z-10">
          <button className="bg-white p-2 rounded-full shadow-sm hover:bg-gray-100">
            <Share2 className="w-4 h-4" />
          </button>
        </div>

        <motion.div
          key={currentIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex items-center justify-center"
        >
          <img
            src={image[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="max-w-full h-auto rounded-lg shadow-md"
          />
        </motion.div>

        {currentIndex > 0 && (
          <button
            onClick={handlePrev}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-sm hover:bg-gray-100"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
        )}

        <button
          onClick={handleNext}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-sm hover:bg-gray-100"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      {/* Dot Navigation */}
      <div className="absolute -bottom-8 left-0 right-0">
        <div className="flex justify-center gap-2">
          {image.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentIndex === index ? "bg-black" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
