import React, { useState, useMemo, useEffect } from "react";
import { SlidersHorizontal, SearchIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Sidebar } from "../components/ShopNew/Sidebar";
import { CategoryFilter } from "../components/ShopNew/CategoryFilter";
import { ProductGrid } from "../components/ShopNew/ProductGrid";
import Pagination from "../components/Shop/components/Content/Pagination";
import PromoBanner from "../components/Layout/Header/PromoBanner";
import {
  HeaderContainer,
  HeaderTitleWrapper,
  HeaderTitle,
} from "../components/Layout/Header/wrapper";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../store/productSlice";

function ShopNew() {
  const dispatch = useDispatch();
  const {
    items: products,
    fetchStatus,
    error,
  } = useSelector((state) => state.products);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [category, setCategory] = useState("tops");
  const [sortBy, setSortBy] = useState("featured");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Fetch products if needed
  useEffect(() => {
    if (fetchStatus === "idle") {
      dispatch(fetchProducts());
    }
  }, [fetchStatus, dispatch]);

  // Filter products by search query
  const filteredProducts = useMemo(() => {
    if (!searchQuery) return products;
    return products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, products]);

  // (Optional) Sort products if required
  const sortedProducts = useMemo(() => {
    // Implement sorting logic if needed
    // For example: if sortBy === "featured", return as is;
    // if sortBy === "priceAsc", return filteredProducts sorted by price ascending, etc.
    return filteredProducts;
  }, [filteredProducts, sortBy]);

  // Pagination
  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
  const currentItems = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedProducts.slice(start, start + itemsPerPage);
  }, [sortedProducts, currentPage, itemsPerPage]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchQuery("");
    setCategory("tops");
    setSortBy("featured");
    setCurrentPage(1);
  };

  return (
    <div className="min-h-screen bg-white mb-12">
      <HeaderContainer className="px-4 sm:px-8 md:px-16 lg:px-24 py-4 sm:py-8 bg-[#FAFAFC] border-b border-gray-300">
        <HeaderTitleWrapper className="self-center px-4 sm:px-8 md:px-16 lg:px-24">
          <HeaderTitle title="Shop" />
        </HeaderTitleWrapper>
        <PromoBanner
          desc1="Discover eco-friendly products that lower your footprint"
          desc2="Share your price budget and we will help you achieve it"
        />
      </HeaderContainer>

      <div className="mx-auto pt-8">
        {/* Top Filters Row */}
        <div className="flex flex-wrap items-center md:mx-10 lg:mx-16 gap-4 relative">
          {/* Filter button on mobile */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setSidebarOpen(true)}
            className="flex items-center justify-center text-gray-600 hover:text-gray-700 bg-white px-3 py-1"
          >
            <SlidersHorizontal className="h-5 w-5 mr-2" />
            <span className="text-sm">Filter</span>
          </motion.button>

          {/* Reset Button */}
          <motion.button
            onClick={handleReset}
            className="text-gray-500 hover:text-gray-700 text-sm border-l border-gray-300 pl-4"
          >
            Reset
          </motion.button>

          {/* Search Input */}
          <div className="flex-1 min-w-[300px] relative">
            <label className="relative group w-full">
              <input
                type="text"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Find the product you're looking for"
                className="w-full placeholder:text-gray-500 group-focus-within:pl-4 text-gray-700 bg-white border border-gray-300 rounded-md pl-10 py-1.5 focus:outline-none focus:border-gray-400 transition-colors"
              />
              <SearchIcon
                className="absolute group-focus-within:hidden top-0 left-2 text-gray-500"
                strokeWidth={1.5}
              />
            </label>
          </div>

          {/* Category and Sort Filters */}
          <CategoryFilter
            category={category}
            sortBy={sortBy}
            onCategoryChange={setCategory}
            onSortChange={setSortBy}
          />
        </div>

        {/* Horizontal line separating filters from content */}
        <div className="border-t border-gray-300 mt-4"></div>

        <div className="flex">
          {/* Sidebar on large screens */}
          <div className="hidden lg:block w-64 shrink-0 border-r border-gray-300">
            <Sidebar isOpen={true} onClose={() => {}} />
          </div>

          {/* Sidebar overlay on small screens */}
          <AnimatePresence>
            {sidebarOpen && (
              <>
                <motion.div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40 lg:hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => setSidebarOpen(false)}
                ></motion.div>

                <motion.div
                  initial={{ x: -320 }}
                  animate={{ x: 0 }}
                  exit={{ x: -320 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  className="fixed inset-y-0 left-0 w-80 bg-white border-r border-gray-300 shadow-lg z-50 overflow-y-auto lg:hidden"
                >
                  <Sidebar
                    isOpen={sidebarOpen}
                    onClose={() => setSidebarOpen(false)}
                  />
                </motion.div>
              </>
            )}
          </AnimatePresence>

          {/* Product Grid Section */}
          <main className="flex-1">
            {fetchStatus === "loading" && (
              <p className="text-center py-10">Loading...</p>
            )}
            {fetchStatus === "failed" && (
              <p className="text-center py-10 text-red-600">Error: {error}</p>
            )}
            {fetchStatus === "succeeded" && (
              <ProductGrid products={currentItems} />
            )}
          </main>
        </div>
      </div>

      {/* Pagination */}
      {fetchStatus === "succeeded" && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
}

export default ShopNew;
