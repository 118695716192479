import React from "react";
import { ChevronDown } from "lucide-react";

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <div className="relative inline-block w-full">
      <div className="relative">
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="appearance-none w-full bg-transparent text-sm px-2 py-1.5 pr-8 cursor-pointer outline-none"
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className="bg-white text-black"
            >
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <ChevronDown className="h-4 w-4 text-gray-500" strokeWidth={2} />
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
