import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ImageSlider } from "../Shop/components/Content/ProductList";

export function AnimatedProductCard({
  product,
  isNew = true,
  isBestSeller = false,
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      className="group border border-gray-200 bg-white  overflow-hidden shadow-sm hover:shadow-md transition-shadow"
    >
      <div className="relative aspect-square">
        <ImageSlider
          images={product.inspirationDesign?.[0]?.images}
          id={product._id}
        />
        {isNew && (
          <motion.span
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="absolute top-2 right-2 bg-blue-500 text-white px-3 py-1 text-xs font-medium rounded-full"
          >
            New
          </motion.span>
        )}
        {isBestSeller && (
          <motion.span
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="absolute top-2 right-2 bg-green-500 text-white px-3 py-1 text-xs font-medium rounded-full"
          >
            Best Seller
          </motion.span>
        )}
      </div>
      <Link to={`/overview/${product._id}`}>
        <div className="flex flex-col items-center justify-center pb-4">
          <p className="text-blue-400 text-sm font-semibold">New</p>
          <h3 className="md:text-lg font-bold text-gray-900">{product.name}</h3>
          <p className="mt-1  text-gray-500">
            Min {product.priceTier ? product.priceTier.price : "N/A"} Pieces
          </p>
        </div>
      </Link>
    </motion.div>
  );
}
