import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

// Async thunk to fetch all materials
export const fetchAllMaterials = createAsyncThunk(
  "assets/fetchAllMaterials",
  async () => {
    const response = await axios.get(`${SERVER_URL}/api/v1/assets/materials`);
    console.log(response.data);
    return response.data;
  }
);

// Async thunk to fetch specific materials
export const fetchMaterial = createAsyncThunk(
  "assets/fetchMaterial",
  async ({ materialCategoryName, materialName }, { rejectWithValue }) => {
    try {
      console.log("from thunk=> ", { materialName, materialCategoryName });

      const response = await axios.get(
        `${SERVER_URL}/api/v1/assets/material/${materialCategoryName}/${materialName}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchModels = createAsyncThunk("assets/fetchModels", async () => {
  const response = await axios.get(`${SERVER_URL}/api/v1/assets/models`);
  console.log(response.data);
  return response.data;
});

// Slice
const assetsSlice = createSlice({
  name: "assets",
  initialState: {
    models: {},
    materials: [],
    selectedMaterial: {},
    allMaterialStatus: "idle",
    materialStatus: "idle",
    modelStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //allmaterials
      .addCase(fetchAllMaterials.pending, (state) => {
        state.allMaterialStatus = "loading";
      })
      .addCase(fetchAllMaterials.fulfilled, (state, action) => {
        state.allMaterialStatus = "succeeded";
        state.materials = action.payload;
      })
      .addCase(fetchAllMaterials.rejected, (state, action) => {
        state.allMaterialStatus = "failed";
        state.error = action.error.message;
      })
      //material
      .addCase(fetchMaterial.pending, (state) => {
        state.materialStatus = "loading";
      })
      .addCase(fetchMaterial.fulfilled, (state, action) => {
        state.materialStatus = "idle";
        state.selectedMaterial = action.payload;
      })
      .addCase(fetchMaterial.rejected, (state, action) => {
        state.materialStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchModels.pending, (state) => {
        state.modelStatus = "loading";
      })
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.modelStatus = "succeeded";
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.modelStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default assetsSlice.reducer;
