import React from "react";
import { motion } from "framer-motion";
import { AnimatedProductCard } from "./ProductCard";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export function ProductGrid({ products }) {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3"
    >
      {products && products.length > 0 ? (
        products.map((product) => (
          <div
            key={product._id}
            className="flex flex-col items-center justify-center"
          >
            <AnimatedProductCard product={product} />
          </div>
        ))
      ) : (
        <p className="col-span-full text-center text-gray-600 py-10">
          No products found
        </p>
      )}
    </motion.div>
  );
}
