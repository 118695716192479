import React from "react";
import Icons from "../../../../assets/icons";
import { HelperContainer, HelperWrapper } from "./wrapper";
export default function Helper({ title, desc }) {
  return (
    <HelperContainer>
      <HelperWrapper>
        <div>
          <h4 className="font-bold text-sm ">{title}</h4>
          <label className="text-sm text-primary w-min">{desc}</label>
        </div>
        <div className="w-1/2 h-max justify-end flex">
          <a href="#">
            <Icons src={"plusRounded"} />
          </a>
        </div>
      </HelperWrapper>
    </HelperContainer>
  );
}
