import React from "react";
import Button from "../../Partials/Button";
import Icons from "../../../assets/icons";

export default function PromoBanner({ desc1, desc2, onPromoClick }) {
  return (
    <div className="font-inter w-full flex flex-col space-y-4 ">
      <div className="flex justify-end md:justify-start w-full">
        <div className="bg-secondary-light rounded-full flex justify-between items-center space-x-2 p-1.5 w-full">
          <span className="text-sm text-gray-600 px-2">{desc1}</span>
          <Button
            className="p-3 border-none bg-secondary"
            onClick={onPromoClick}
          >
            <Icons src="plus" />
          </Button>
        </div>
      </div>

      <div className="flex md:justify-start w-full">
        <div className="bg-secondary-light rounded-full flex justify-between items-center space-x-2 p-1.5 w-full">
          <span className="text-sm text-gray-600 px-2">{desc2}</span>
          <Button
            className="p-3 border-none bg-secondary"
            onClick={onPromoClick}
          >
            <Icons src="plus" />
          </Button>
        </div>
      </div>
    </div>
  );
}
