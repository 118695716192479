import React, { useEffect, useState } from "react";
import { ProductListWrapper } from "../../wrapper";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../../../store/productSlice";
import Button from "../../../Partials/Button";
import Icons from "../../../../assets/icons";
import { Link } from "react-router-dom";
import ImageSliderControls from "../../../Partials/ImageSliderControls";

export default function ProductList() {
  const dispatch = useDispatch();
  const {
    items: products,
    fetchStatus,
    error,
  } = useSelector((state) => state.products);

  console.log(products);
  if (fetchStatus === "idle") {
    dispatch(fetchProducts());
  } else if (fetchStatus === "succeeded" && products.length === 0) {
    dispatch(fetchProducts());
  }

  const renderProductList = () => {
    if (fetchStatus === "loading") return <p>Loading...</p>;
    if (fetchStatus === "failed") return <p>Error: {error}</p>;

    return products.map((product) => (
      <ProductCard key={product._id} product={product} />
    ));
  };

  return <ProductListWrapper>{renderProductList()}</ProductListWrapper>;
}

function ProductCard({ product }) {
  return (
    <div className="w-1/3 basis-[33.333333%] border-e border-b border-gray-300">
      <article className="flex flex-col items-center mb-5">
        <ImageSlider
          images={product.inspirationDesign?.[0]?.images}
          id={product._id}
        />
        <Link to={`/overview/${product._id}`}>
          <div className="flex flex-col justify-center space-y-1">
            <span className="font-medium text-sm text-tertiary-light text-center">
              New
            </span>
            <h2 className="font-semibold text-base text-primary">
              {product.name}
            </h2>
            <span className="font-normal text-sm text-secondary-dark text-center">
              {product.priceTier ? product.priceTier.price : "N/A"}
            </span>
          </div>
        </Link>
      </article>
    </div>
  );
}

// ImageSlider Component
export function ImageSlider({ images, id }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageChange = (direction) => {
    setCurrentImageIndex((prevIndex) =>
      direction === "next"
        ? (prevIndex + 1) % images.length
        : (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative w-full h-full group">
      <Link to={`/overview/${id}`}>
        <Button className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-10 h-10 bg-secondary-light opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Icons src="edit" />
        </Button>
      </Link>

      <Link to={`/overview/${id}`}>
        <img
          src={images[currentImageIndex]}
          alt="Product"
          className="object-cover"
        />
      </Link>
      {images.length > 1 && (
        <ImageSliderControls
          onPrev={() => handleImageChange("prev")}
          onNext={() => handleImageChange("next")}
        />
      )}
    </div>
  );
}
