export const HeaderContainer = (props) => (
  <section
    className={`flex flex-col md:flex-row justify-between items-baseline space-y-4 md:space-y-0 md:space-x-8 ${props.className}`}
  >
    {props.children}
  </section>
);

export const HeaderTitleWrapper = (props) => (
  <div
    className={`font-inter w-full justify-start text-center md:text-left ${props.className}`}
  >
    {props.children}
  </div>
);

export const HeaderTitle = (props) => (
  <h2 className="font-semibold text-3xl md:text-5xl lg:text-6xl">
    {props.title}
  </h2>
);

export const HeaderDesc = (props) => (
  <span className="font-normal text-base md:text-lg text-secondary-dark block mt-2">
    {props.desc}
  </span>
);
