import React from "react";

export default function PaginationButtonRight({onClick, ariaLabel}) {
  return (
    <button onClick={onClick} aria-label={ariaLabel}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8.25 4.5L15.75 12L8.25 19.5"
          stroke="#848484"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
