import React, { useState, useRef, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductById } from "../store/productSlice";
import {
  CheckCircleIcon,
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ImageSlider from "../components/Products/ImageSlider";
import Collapsible from "../components/Products/Collpsable";
import Icons from "../components/Products/icons";
import Helper from "../components/Overview/ProductMenu/components/Helper";
import {
  CategoryWrapper,
  HelperContainer,
  HelperWrapper,
  OptionsWrapper,
  SelectBtnWrapper,
} from "../components/Overview/ProductMenu/components/wrapper";
import SelectBtn from "../components/Overview/ProductMenu/components/SelectBtn";
import SelectHeader from "../components/Overview/ProductMenu/components/SelectHeader";
import Button from "../components/Partials/Button";
import {
  HeaderContainer,
  HeaderDesc,
  HeaderTitle,
  HeaderTitleWrapper,
} from "../components/Layout/Header/wrapper";
import PromoBanner from "../components/Layout/Header/PromoBanner";

const TShirtCustomizer = memo(() => {
  document.title = "Products";

  // Section Refs
  const designRef = useRef(null);
  const quantityRef = useRef(null);
  const sizeRef = useRef(null);
  const finishRef = useRef(null);

  const [sectionProgress, setSectionProgress] = useState({
    design: false,
    quantity: false,
    size: false,
    finish: false,
  });

  const [selectedValues, setSelectedValues] = useState({
    design: null,
    quantity: null,
    size: null,
    finish: null,
  });

  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [selectedFinish, setSelectedFinish] = useState(null);
  const [finishMultiplier, setFinishMultiplier] = useState(1);

  const [toastState, setToastState] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const [sectionCollapse, setSectionCollapse] = useState({
    yourDesign: false,
    inspirations: false,
  });

  const { id } = useParams(); // Get the product ID from the URL
  const dispatch = useDispatch();

  // Get the product details and status from Redux store
  const product = useSelector((state) => state.products.productDetails[id]);
  const status = useSelector((state) => state.products.status);

  // Fetch product details if needed
  useEffect(() => {
    if (!product && status !== "loading" && status !== "failed") {
      dispatch(fetchProductById(id));
    }
  }, [dispatch, id, product, status]);

  const quantityPrices = product?.quantityPrices || [];
  const sizeChart = product?.sizeChart || { women: [], men: [] };
  const materialTypes = product?.materialTypes || [];

  useEffect(() => {
    if (selectedFinish && materialTypes.length > 0) {
      const index = materialTypes.findIndex((m) => m === selectedFinish);
      // Example multiplier logic
      setFinishMultiplier(1 + index * 0.1);
    } else {
      setFinishMultiplier(1);
    }
  }, [selectedFinish, materialTypes]);

  const totalPrice = (() => {
    if (!selectedQuantity || !finishMultiplier) return 0;
    const currentPrice = quantityPrices.find(
      (q) => q.quantity === selectedQuantity
    );
    if (!currentPrice) return 0;
    return (
      currentPrice.quantity * currentPrice.pricePerPiece * finishMultiplier
    );
  })();

  const scrollToNextSection = (currentSection) => {
    const sectionsOrder = ["design", "quantity", "size", "finish"];
    const currentIndex = sectionsOrder.indexOf(currentSection);
    const nextSection = sectionsOrder[currentIndex + 1];
    if (nextSection) {
      if (selectedValues[currentSection]) {
        const nextRef = {
          design: designRef,
          quantity: quantityRef,
          size: sizeRef,
          finish: finishRef,
        }[nextSection];
        nextRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleSelectDesign = (value) => {
    setSelectedValues((prev) => ({ ...prev, design: value }));
    setSectionProgress((prev) => ({ ...prev, design: true }));
    scrollToNextSection("design");
  };

  const handleSelectQuantity = (quantity) => {
    setSelectedQuantity(quantity);
    setSelectedValues((prev) => ({ ...prev, quantity }));
    setSectionProgress((prev) => ({ ...prev, quantity: true }));
    scrollToNextSection("quantity");
  };

  const handleSelectSize = (size) => {
    setSelectedValues((prev) => ({ ...prev, size }));
    setSectionProgress((prev) => ({ ...prev, size: true }));
    scrollToNextSection("size");
  };

  const handleSelectFinish = (finish) => {
    setSelectedFinish(finish);
    setSelectedValues((prev) => ({ ...prev, finish }));
    setSectionProgress((prev) => ({ ...prev, finish: true }));
  };

  const handleCustomizeClick = () => {
    window.location.href = "/customize";
    handleSelectDesign("customize");
  };

  const handleToggleYourDesign = () => {
    setSectionCollapse((prev) => ({ ...prev, yourDesign: !prev.yourDesign }));
    if (!sectionCollapse.yourDesign) {
      handleSelectDesign("yourDesign");
    }
  };

  const handleToggleInspirations = () => {
    setSectionCollapse((prev) => ({
      ...prev,
      inspirations: !prev.inspirations,
    }));
    if (!sectionCollapse.inspirations) {
      handleSelectDesign("inspirations");
    }
  };

  // This function will be passed down to PromoBanner
  const handlePromoClick = () => {
    setToastState({
      show: true,
      message: "Offer Applie Successfully!",
      type: "success",
    });
  };

  if (status === "loading") return <p>Loading product details...</p>;
  if (!product) return <p>No product found</p>;

  return (
    <div className="relative mb-24">
      <div className="px-24 py-24 font-inter space-y-10 relative">
        <HeaderContainer>
          <HeaderTitleWrapper>
            <HeaderTitle title={product?.name} />
            <HeaderDesc desc={product?.description} />
          </HeaderTitleWrapper>
          <PromoBanner
            desc1={"Earn up to 10% back in loyalty points with every order."}
            desc2={"Subscribe for premium 3D renders and marketing materials"}
            onPromoClick={handlePromoClick}
          />
        </HeaderContainer>
      </div>
      <div className="flex flex-col md:flex-row relative mx-12 md:mx-20 lg:mx-24">
        {/* Left Column (Fixed) */}
        <div className="md:w-2/3 w-full md:sticky top-0 bg-white flex-shrink-0">
          <ImageSlider image={product?.inspirationDesign?.[0]?.images} />
        </div>

        {/* Right Column (Scrollable) */}
        <div className="md:w-1/3 w-full overflow-y-auto max-h-screen hide-scrollbar p-4 space-y-10">
          {/* Design Section */}
          <div ref={designRef}>
            <CategoryWrapper>
              <OptionsWrapper>
                <SelectHeader title={"Design"} desc={"Choose your design"} />
              </OptionsWrapper>
              <button
                onClick={handleCustomizeClick}
                className="border-2 w-full hover:border-blue-400 transition-colors duration-200 ease-in text-left p-2 py-6 rounded-md flex justify-between items-center"
              >
                <span>Customize</span>
                {/* {sectionCollapse.yourDesign ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )} */}
              </button>
              {/* Your Design */}
              <div className="w-full">
                <button
                  onClick={handleToggleYourDesign}
                  className="border-2 w-full hover:border-blue-400 transition-colors duration-200 ease-in text-left p-2 py-6 rounded-md flex justify-between items-center"
                >
                  <span>Your Design</span>
                  {/* {sectionCollapse.yourDesign ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )} */}
                </button>
                <AnimatePresence>
                  {sectionCollapse.yourDesign && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="overflow-hidden p-2 mt-2"
                    >
                      <div className="bg-secondary-light rounded-md p-3 space-y-2">
                        <div className="flex justify-between">
                          <label className="font-medium text-sm">
                            All Designs
                          </label>
                          <Button className={"w-5 p-1 border-primary h-5"}>
                            <Icons src={"arrowDown"} />
                          </Button>
                        </div>
                        {/* Placeholder designs */}
                        <div className="grid grid-cols-4 gap-2">
                          {[...Array(4)].map((_, index) => (
                            <SelectBtnWrapper
                              key={index}
                              className={
                                "h-auto items-center justify-center flex"
                              }
                            >
                              <div className="bg-gray-200 w-full h-16 flex items-center justify-center">
                                <span className="text-xs">
                                  Design {index + 1}
                                </span>
                              </div>
                            </SelectBtnWrapper>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Inspirations */}
              <div className="w-full">
                <button
                  onClick={handleToggleInspirations}
                  className="border-2 hover:border-blue-400 transition-colors duration-200 ease-in w-full text-left p-2 py-6 rounded-md flex justify-between items-center"
                >
                  <span>Inspirations</span>
                  {/* {sectionCollapse.inspirations ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )} */}
                </button>
                <AnimatePresence>
                  {sectionCollapse.inspirations && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="overflow-hidden p-2 mt-2"
                    >
                      <div className="bg-secondary-light rounded-md p-3 space-y-2">
                        Inspirations Content...
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <Helper
                title={"Need Help with Your Design?"}
                desc={
                  "Upload your reference image and we’ll create the exact design you need, ready for you to personalize."
                }
              />
            </CategoryWrapper>
          </div>

          {/* Quantity Section */}
          <div ref={quantityRef}>
            <CategoryWrapper>
              <OptionsWrapper>
                <SelectHeader
                  title={"Quantity"}
                  desc={"Choose the exact amount you’ll need"}
                />
                <SelectBtnWrapper>
                  <div className="flex flex-col w-full space-y-2">
                    {quantityPrices.map((price) => (
                      <button
                        key={price.quantity}
                        onClick={() => handleSelectQuantity(price.quantity)}
                        className={`flex justify-between items-center p-2 border ${
                          selectedQuantity === price.quantity
                            ? "border-primary bg-primary-light text-primary"
                            : "border-gray-300 bg-white"
                        } rounded-md transition-colors`}
                      >
                        <span className="font-semibold text-sm">
                          {price.quantity}
                        </span>
                        <div className="text-right">
                          <div className="text-sm">
                            ${price.pricePerPiece.toFixed(2)}/piece
                          </div>
                          <div className="text-xs text-secondary-dark">
                            ${price.total.toFixed(2)}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </SelectBtnWrapper>
              </OptionsWrapper>
            </CategoryWrapper>
          </div>

          {/* Size Section */}
          <div ref={sizeRef}>
            <CategoryWrapper>
              <OptionsWrapper>
                <SelectHeader title={"Size"} desc={"Pick the perfect fit"} />
              </OptionsWrapper>
              {sizeChart.women && sizeChart.women.length > 0 && (
                <OptionsWrapper className="space-y-4">
                  <SelectBtn
                    name={"Women"}
                    onClick={() => handleSelectSize("women")}
                  />
                  <div className="grid grid-cols-7 gap-2 w-full mt-2">
                    {sizeChart.women.map((item) => (
                      <SelectBtnWrapper
                        key={item.size}
                        className={"p-2 h-auto"}
                      >
                        <button
                          className="flex flex-col items-center justify-center w-full h-full border border-gray-300 rounded-md p-2"
                          onClick={() => handleSelectSize(item.size)}
                        >
                          <span className="font-semibold text-sm text-primary">
                            {item.size}
                          </span>
                          <span className="text-xs text-secondary-dark">
                            {item.chest}”
                          </span>
                        </button>
                      </SelectBtnWrapper>
                    ))}
                  </div>
                  {sizeChart.men && sizeChart.men.length > 0 && (
                    <SelectBtn
                      name={"Men"}
                      onClick={() => handleSelectSize("men")}
                    />
                  )}
                </OptionsWrapper>
              )}
              <OptionsWrapper>
                <HelperContainer>
                  <HelperWrapper>
                    <div className="w-1/4 h-auto flex items-center">
                      <Icons src={"measure"} />
                    </div>
                    <div>
                      <h4 className="font-bold text-sm ">
                        Need Help with your design?
                      </h4>
                      <label className="text-sm text-primary w-min">
                        Get a better understanding of how to choose the sizes.
                      </label>
                    </div>
                    <div className="w-1/3 h-max justify-end flex">
                      <a href="#">
                        <Icons src={"plusRounded"} />
                      </a>
                    </div>
                  </HelperWrapper>
                </HelperContainer>
              </OptionsWrapper>
            </CategoryWrapper>
          </div>

          {/* Finish Section */}
          <div ref={finishRef}>
            <CategoryWrapper>
              <OptionsWrapper>
                <SelectHeader
                  title={"Finish"}
                  desc={"Pick your favorite material"}
                />
              </OptionsWrapper>
              <OptionsWrapper className={"grid grid-cols-2 gap-2"}>
                {materialTypes.map((type, index) => (
                  <SelectBtnWrapper
                    key={index}
                    className={"p-2 h-auto space-y-5"}
                  >
                    <button
                      onClick={() => handleSelectFinish(type)}
                      className={`flex flex-col items-center justify-center w-full h-full border ${
                        selectedFinish === type
                          ? "border-primary bg-primary-light text-primary"
                          : "border-gray-300 bg-white"
                      } rounded-md p-2`}
                    >
                      <span className="font-semibold text-base text-primary">
                        Polyester
                      </span>
                      <span className="font-normal text-xs text-secondary-dark w-min">
                        {type} Polyester
                      </span>
                    </button>
                  </SelectBtnWrapper>
                ))}
              </OptionsWrapper>
              <OptionsWrapper>
                <Helper
                  title={"Want to learn more about this Product?"}
                  desc={
                    "Apple Pencil has set the standard for how drawing, painting, handwriting, and note-taking should feel — intuitive, precise, and magical."
                  }
                />
              </OptionsWrapper>
            </CategoryWrapper>
          </div>

          {/* Price & Add to Bag */}
          <CategoryWrapper>
            <OptionsWrapper>
              <div className="font-semibold text-[28px] text-primary">
                ${totalPrice.toFixed(2)}
              </div>
              <label className="font-medium text-lg text-primary">
                {product.name}
              </label>
              <div className="flex gap-2 items-baseline">
                <Icons src={"truck"} />
                <div className="w-max">
                  <div className="font-medium text-sm text-primary">
                    Produced In:
                  </div>
                  <div className="font-normal text-sm text-primary">
                    {product.productionTime} Days
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <Button
                  className={
                    "border-0 bg-primary text-white w-full py-3 flex gap-2 mt-5"
                  }
                >
                  <Icons src={"bag2"} />
                  <span className="font-semibold text-base text-white">
                    Add to Bag
                  </span>
                </Button>
              </div>
            </OptionsWrapper>
          </CategoryWrapper>

          {/* Toast Notification */}
          <AnimatePresence>
            {toastState.show && (
              <motion.div
                className={`fixed top-4 right-4 bg-white shadow-lg rounded-lg px-4 py-3 flex items-center justify-between z-50 ${
                  toastState.type === "success"
                    ? "border-2 border-green-500 text-green-500"
                    : "border-2 border-red-500 text-red-500"
                }`}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
              >
                <span>
                  {toastState.type === "success" ? (
                    <CheckCircleIcon className="w-6 h-6 mr-2" />
                  ) : (
                    <XCircleIcon className="w-6 h-6 mr-2" />
                  )}
                </span>
                <p className="text-sm font-medium">{toastState.message}</p>
                <button
                  onClick={() =>
                    setToastState((prev) => ({ ...prev, show: false }))
                  }
                  className="ml-4 text-gray-500 hover:text-gray-700"
                >
                  <XCircleIcon className="w-5 h-5" />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
});

export default TShirtCustomizer;
