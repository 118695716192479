import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer"; // Import your footer
import { Outlet } from "react-router-dom";


export default function Layout() {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header/Navbar */}
      <Navbar />

      {/* Main content will be displayed here */}
      <main className="flex-grow">
        <Outlet />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
